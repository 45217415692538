<template>
  <section v-show="user.information" class="addin">

    <div v-if="is_1_8_supported">
      
      <ComposeLocalNotSending v-if="!isWeb && checkValidVersion('4.3.0')"></ComposeLocalNotSending>
      <ComposeLocalNotSending_4_2 v-if="!isWeb && checkValidVersion('4.2.0') && !checkValidVersion('4.3.0')"></ComposeLocalNotSending_4_2>
      <ComposeLocalNotSending_4_1 v-if="!isWeb && !checkValidVersion('4.2.0')"></ComposeLocalNotSending_4_1>

      <ComposeLocal v-if="isWeb && checkValidVersion('4.3.0')"></ComposeLocal>
      <ComposeLocal_4_2 v-if="isWeb && checkValidVersion('4.2.0')  && !checkValidVersion('4.3.0')"></ComposeLocal_4_2>
      <ComposeLocal_4_1 v-if="isWeb && !checkValidVersion('4.2.0')"></ComposeLocal_4_1>
      
    </div>
    <div v-if="!is_1_8_supported">
      <ComposeOld></ComposeOld>
    </div>

  </section>
</template>
<script>
export default {
  components: {
    ComposeLocal: () => import("@/components/ComposeLocal"),
    ComposeLocal_4_1: () => import("@/components/ComposeLocal_4_1"),
    ComposeLocal_4_2: () => import("@/components/ComposeLocal_4_2"),
    ComposeLocalNotSending: () => import("@/components/ComposeLocalNotSending"),
    ComposeLocalNotSending_4_1: () => import("@/components/ComposeLocalNotSending_4_1"),
    ComposeLocalNotSending_4_2: () => import("@/components/ComposeLocalNotSending_4_2"),
    ComposeOld: () => import("@/components/ComposeOld")
  },
  data() {
  },
  methods: {
    async init() {
    }
  },
  computed: {
    isWeb(){
      if(this.$Office.context.mailbox.diagnostics.hostName  == "OutlookWebApp")
      {
        return true;
      }else{
        return false;
      }
    },
    is_1_8_supported() {
      if (this.$Office.context.requirements.isSetSupported('Mailbox', '1.8')) {
        return true;
      }else{
        return false;
      }
    }
  },
  mounted() {
    console.log("Compose mounted");
    this.init();
  },
};
</script>
<style></style>
